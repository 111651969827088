<template>
  <div>
    <p class="ideas-home-title mt-0">
      {{ $t('pages.pricing.ideas') }}
    </p>

    <v-row>
      <v-col :cols="12" :md="6">
        <add-new-card
          :title="`${$t('pages.ideaCollection.createNewIdea')}`"
          horizontal
          min-height="84px"
          :disabled="!isRoleOwner"
          @click="onClick"
        />
      </v-col>
      <v-col v-if="idea" :cols="12" :md="6">
        <idea-card :idea="idea" min-height="84px" />
      </v-col>
    </v-row>

    <div class="go-to-collection">
      <router-link :to="{name: 'idea-collection'}">
        {{ $t('goToCollection') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import AddNewCard from '@/components/_v2/AddNewCard.vue'
import IdeaCard from '@/views/Home/IdeaHome/Components/Ideas/IdeaCard.vue'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Ideas',

  components: { AddNewCard, IdeaCard },

  mixins: [MixinIdeaRoles],

  computed: {
    ...mapGetters('user', [
      'ideasCount',
      'ideasInPlan',
      'isSubscribed',
      'isTemplateAdmin'
    ]),

    activeIdeaId () {
      return this.$store.state.idea.id
    },

    idea () {
      if (this.$store.state.ideaCollection.ideas.length) {
        let idea = this.$store.state.ideaCollection.ideas.find(item => item.id === this.activeIdeaId)

        if (!idea) {
          idea = this.$store.state.ideaCollection.sharedIdeas.find(item => item.idea.id === this.activeIdeaId).idea
        }

        return idea
      }

      return null
    },

    isIdeasLimitReached () {
      return !this.isTemplateAdmin && this.ideasCount >= this.ideasInPlan
    }
  },

  methods: {
    ...mapMutations('ui', ['setDialogUpgradeState']),

    onClick () {
      if (this.isIdeasLimitReached || !this.isSubscribed) {
        this.setDialogUpgradeState(true)

        return
      }
      this.$router.push({ name: 'select-idea' })
    }
  }
}
</script>

<style scoped lang="scss">
.ideas-home-title {
  font-size: 24px;
  margin-bottom: 28px;
}

.go-to-collection {
  margin-top: 15px;
  a {
    text-decoration: none;
    font-size: 18px;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

<template>
  <ib-card class="user-card-wrapper d-flex flex-fill flex-column flex-md-row align-items-center align-items-md-stretch">
    <!-- Avatar -->
    <ib-user-avatar :user="user" class="ml-md-1 mr-md-3" />
    <!-- /Avatar -->

    <!-- Content -->
    <div class="user-wrapper d-flex flex-column justify-content-center flex-grow-1 text-center text-md-left my-2">
      <span v-if="fullName" class="user-name three-dots">{{ fullName }}</span>
      <span v-else class="no-user-name three-dots">{{ $t('pages.users.pendingInvite') }}</span>
      <span class="user-email three-dots">{{ email }}</span>
    </div>
    <!-- /Content -->

    <user-role v-if="role" :role="role" class="mt-2 mt-md-0 mr-md-2" />
    <!-- Collaborator Ideas -->
    <div v-else class="d-flex justify-content-center align-items-center mt-2 mt-md-0 mr-md-4">
      <div v-for="collaboratorIdea in userIdeas" :key="collaboratorIdea.id" class="avatar-item">
        <ib-idea-avatar
          size="md"
          :idea="collaboratorIdea.idea"
          :text="collaboratorIdea.difference"
        />
      </div>
    </div>

    <!-- Options -->
    <el-dropdown
      v-if="id"
      class="action-items"
      trigger="click"
      @command="handleIdeaCommand"
    >
      <span
        class="el-dropdown-link"
        @click="preventEmitting"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 341.333 341.333" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M170.667 85.333c23.573 0 42.667-19.093 42.667-42.667C213.333 19.093 194.24 0 170.667 0S128 19.093 128 42.667c0 23.573 19.093 42.666 42.667 42.666zM170.667 128C147.093 128 128 147.093 128 170.667s19.093 42.667 42.667 42.667 42.667-19.093 42.667-42.667S194.24 128 170.667 128zM170.667 256C147.093 256 128 275.093 128 298.667c0 23.573 19.093 42.667 42.667 42.667s42.667-19.093 42.667-42.667C213.333 275.093 194.24 256 170.667 256z"/></svg>
      </span>
      <el-dropdown-menu slot="dropdown" class="action-dropdown">
        <el-dropdown-item
          :command="{ name: 'edit' }"
        >
          {{ $t('edit') }}
        </el-dropdown-item>
        <el-dropdown-item :command="{ name: 'delete' }">
          {{ $t('delete') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- /Options -->
  </ib-card>
</template>

<script>
import UserRole from './UserRole'

export default {
  name: 'UserCard',

  components: {
    UserRole
  },

  props: {
    email: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      default: null
    },
    ideas: {
      type: Array,
      default: null
    },
    role: {
      type: Number,
      default: null
    },
    id: {
      type: Number,
      default: null
    }
  },

  computed: {
    fullName () {
      if (this.user) {
        const { firstName, lastName } = this.user

        return this.user && firstName && lastName ? `${firstName} ${lastName}` : null
      }

      return null
    },

    userIdeas () {
      const limit = 5
      const count = this.ideas.length

      if (count > (limit + 1)) {
        const difference = count - limit
        const slicedIdeas = this.ideas.slice(0, limit)
        slicedIdeas.push({
          id: 0,
          difference: `+${difference.toString()}`
        })

        return slicedIdeas
      }

      return this.ideas
    }
  },

  methods: {
    handleIdeaCommand (command) {
      switch (command.name) {
        case 'edit':
          this.$emit('edit', this.id)
          break
        case 'delete':
          this.$emit('delete', this.id)
          break
      }
    },

    preventEmitting (e) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
}
</script>

<style scoped>
.avatar-item:not(:first-child) {
  margin-left: -20px;
  z-index: 1;
}

.user-card-wrapper {
  padding: 10px;
}

.user-wrapper {
  white-space: nowrap;
  overflow: hidden;
}

.user-email {
  color: silver;
}

.no-user-name {
  color: silver;
  font-style: italic;
}

.action-items {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  cursor: pointer;
  fill: silver;
  z-index: 1;
}
</style>

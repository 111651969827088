<template>
  <ib-card
    class="idea-card-wrapper d-flex align-center"
    :style="{minHeight: minHeight}"
  >
    <ib-idea-avatar :idea="idea" class="mr-4" />
    <div>
      <p class="m-0">
        {{ idea.name }}
      </p>
      <p class="m-0 o-50">
        {{ idea.catalogueIdea ? idea.catalogueIdea.name : 'Other ' + idea.catalogueCategory.name }}
      </p>
    </div>
  </ib-card>
</template>

<script>
export default {
  name: 'IdeaCard',

  props: {
    idea: {
      type: Object,
      required: true
    },

    minHeight: {
      type: String,
      default: '0'
    }
  }
}
</script>

<style scoped lang="scss">
.idea-card-wrapper {
  padding: 0 0 0 14px;
}
</style>

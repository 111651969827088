<template>
  <div>
    <p class="home-cards-title mt-0">
      {{ $t('continueWhereYouLeft') }}
    </p>

    <v-row class="d-flex">
      <v-col class="d-flex" :cols="12" :lg="6" :xl="4">
        <idea-home-card
          class="w-100"
          :title="`${$t('sidebar.businessGuide')}`"
          :img="`${require('@/assets/img/illustrations/home/business-guide.svg')}`"
          :to="{name: 'business-guide'}"
        >
          <div style="width: 200px;">
            <el-progress class="progress-bar" :percentage="completedPercentage" :stroke-width="8" :color="progressBarCustomColorMethod" />
          </div>
        </idea-home-card>
      </v-col>
      <v-col class="d-flex" :cols="12" :lg="6" :xl="4">
        <idea-home-card
          class="w-100"
          :title="`${$t('sidebar.financialPlan')}`"
          :img="`${require('@/assets/img/illustrations/home/financial-plan.svg')}`"
          :to="{name: 'financial-plan'}"
        />
      </v-col>
      <v-col class="d-flex" :cols="12" :lg="6" :xl="4">
        <idea-home-card
          class="w-100"
          :title="`${$t('sidebar.businessPlan')}`"
          :img="`${require('@/assets/img/illustrations/home/business-plan.svg')}`"
          :show-upgrade="showUpgrade"
          :to="{name: 'business-plan'}"
        >
          <span class="home-card-value font-outfit-medium mr-1">{{ businessPlanPages }}</span> <span class="o-50">{{ $t('pagesHomeCard') }}</span>
        </idea-home-card>
      </v-col>
      <v-col class="d-flex" :cols="12" :lg="6" :xl="4">
        <idea-home-card
          class="w-100"
          :title="`${$t('sidebar.whiteboard')}`"
          :img="`${require('@/assets/img/illustrations/home/whiteboard.svg')}`"
          :to="{name: 'whiteboard'}"
        />
      </v-col>
      <v-col class="d-flex" :cols="12" :lg="6" :xl="4">
        <idea-home-card
          class="w-100"
          :title="`${$t('sidebar.ideaPlan')}`"
          :img="`${require('@/assets/img/illustrations/home/idea-plan.svg')}`"
          :to="{name: 'idea-plan'}"
        >
          <span class="home-card-value font-outfit-medium mr-1">{{ getCountNotes }}</span> <span class="o-50">{{ $t('notes') }}</span>
        </idea-home-card>
      </v-col>
      <v-col class="d-flex" :cols="12" :lg="6" :xl="4">
        <idea-home-card
          class="w-100"
          :title="`${$t('sidebar.validation')}`"
          :img="`${require('@/assets/img/illustrations/home/validation.svg')}`"
          :show-upgrade="showUpgrade"
          :to="{name: 'validation-idea-score'}"
        >
          <span class="home-card-value font-outfit-medium mr-1">{{ ideaScore }}</span> <span class="o-50">{{ $t('pages.businessGuide.navigation.ideaScore') }}</span>
        </idea-home-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IdeaHomeCard from '@/views/Home/IdeaHome/Components/IdeaHomeCard.vue'
import MixinProgressBar from '@/mixins/progressBar'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeCards',

  components: { IdeaHomeCard },

  mixins: [MixinUserSubscription, MixinProgressBar],

  computed: {
    ...mapGetters('idea/ideaPlan', ['getCountNotes']),
    ...mapGetters('idea', ['getIdeaScore', 'countStoryModeCompletedSteps', 'getBusinessPlanSections']),

    showUpgrade () {
      return this.subscriptionIsFreeTrial || this.subscriptionIsDreamer
    },

    businessPlanPages () {
      return this.getBusinessPlanSections.filter(item => item.sectionName === 'Custom').length + this.countStoryModeCompletedSteps + 1
    },

    ideaScore () {
      const ideaScore = this.getIdeaScore
      if (!ideaScore) return '-'
      if (!ideaScore.calculation) return '-'

      return ideaScore.calculation.total
    }
  },

  watch: {
    '$store.state.idea.ideaPlan.ideaPlans': {
      handler (value) {
        this.getIdeaPlan(value[0].id)
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('idea/ideaPlan', [
      'getIdeaPlan'
    ])
  }
}
</script>

<style lang="scss" scoped>
.home-cards-title {
  font-size: 24px;
  margin-bottom: 28px;
}

.home-card-value {
  font-size: 22px;
}

.progress-bar {
  margin-top: 8px;
  ::v-deep {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .el-progress__text {
      font-family: outfit-medium;
      font-size: 22px !important;
      margin-left: 0;
      margin-right: 10px;
    }
  }
}
</style>

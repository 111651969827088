<template>
  <div class="gamification-progress-bar-wrapper d-flex flex-column flex-md-row align-md-center flex-lg-column align-lg-start justify-content-between align-lg-stretch flex-grow-1">
    <div class="progress-bar">
      <p class="progress font-outfit-regular">
        {{ $t('gamification.progress') }}
      </p>
      <el-progress
        :percentage="getPercentage"
        :show-text="false"
        :stroke-width="17"
        color="#42D778"
      />
      <p class="ma-0 o-50 mt-1 font-outfit-regular">
        {{ getPercentage }}% <span class="text-lowercase">{{ $t('complete') }}</span>
      </p>
    </div>
    <div class="unlock-reward-wrapper">
      <ib-button
        :disabled="getPercentage !== 100 || isAwardActivated"
        :loading="loading"
        icon="ib-icon-unlock"
        @click="onClick"
      >
        <span class="btn-text font-outfit-semi-bold">{{ $t('gamification.unlockReward') }}</span>
      </ib-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'GamificationProgress',

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapGetters('user/gamification', ['getPercentage', 'isAwardActivated'])
  },

  methods: {
    ...mapActions('user/gamification', ['unlockReward']),
    ...mapMutations('user', ['setFreeTrial']),

    async onClick () {
      try {
        this.loading = true
        const response = await this.unlockReward()
        this.$intercom.trackEvent('Unlocked Reward')
        this.$intercom.update({
          UnlockReward: true,
          AccountValid: response.userCreatedAt,
          PlanExpiry: response.userCreatedAt
        })
        this.setFreeTrial(response.userCreatedAt)
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.gamification-progress-bar-wrapper {

  .progress {
    font-size: 20px;
    margin: 0 0 15px;
  }

  .progress-bar {
    margin: 0 0 30px;

    @include media-breakpoint-up($md) {
      width: 50%;
      margin: 0;
    }

    @include media-breakpoint-up($lg) {
      width: 100%;
    }
  }

  .unlock-reward-wrapper {
    button {
      width: 100%;
    }
  }

  .btn-text {
    font-size: 20px;
  }
}
</style>

<template>
  <div class="gamification-award-wrapper d-flex flex-column align-center justify-content-between">
    <div class="badge-wrapper">
      <img
        class="badge"
        :src="require(`@/assets/img/gamification/badge/${getCountCompletedItems}.png`)"
        alt="badge"
      >
    </div>
    <div class="w-100">
      <v-alert
        rounded
        color="primary"
        text
        dense
        class="gamification-alert ma-0"
      >
        <span class="text font-outfit-regular">{{ $t('gamification.awardMessage') }}</span>
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GamificationAward',

  computed: {
    ...mapGetters('user/gamification', ['getCountCompletedItems'])
  }
}
</script>

<style scoped lang="scss">
.gamification-award-wrapper {
  .badge-wrapper {
    margin-bottom: 21px;

    @include media-breakpoint-up($md) {
      margin-bottom: 23px;
    }

    .badge {
      max-width: 120px;
      transform: scale(1.5);
    }
  }

  .text {
    font-size: 14px;

    @include media-breakpoint-up($md) {
      font-size: 16px;
    }
  }

  .gamification-alert {
    padding: 3px 10px;
    border-radius: 8px;
  }
}
</style>

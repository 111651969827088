<template>
  <div class="role-wrapper d-flex align-items-center flex-shrink-0">
    <span v-if="isGuest" class="tag guest-tag">{{ $t(`userRoles.guest`) }}</span>
    <span v-if="isBuddy" class="tag buddy-tag">{{ $t(`userRoles.buddy`) }}</span>
    <span v-if="isOwner" class="tag owner-tag">{{ $t(`userRoles.owner`) }}</span>
  </div>
</template>

<script>
import UserRoleEnum from '@/constants/UserRoleEnum'

export default {
  name: 'UserRole',

  props: {
    role: {
      type: Number,
      default: null
    }
  },

  computed: {
    isGuest () {
      return this.role === UserRoleEnum.GUEST
    },
    isBuddy () {
      return this.role === UserRoleEnum.BUDDY
    },
    isOwner () {
      return this.role === UserRoleEnum.OWNER
    }
  }
}
</script>

<style scoped>
.role-wrapper {
  width: 20%;
  text-align: center;
}

.tag {
  width: 100%;
  padding: 3px 0;
  border-radius: 5px;
}

.guest-tag {
  color: #3566bc;
  background-color: #d4dff1;
}

.buddy-tag {
  color: #f0af2c;
  background-color: #fdf4e2;
}

.owner-tag {
  background-color: #f5f6fa;
}
</style>

<template>
  <div class="gamification-items-list-wrapper">
    <template v-for="item in getItems">
      <div
        :key="item.key"
        class="item-wrapper d-flex justify-content-between"
      >
        <component
          :is="!item.complete ? 'ib-tooltip' : 'div'"
          :content="$t(`gamification.tooltips.${item.key}`)"
        >
          <p
            class="item font-outfit-regular ma-0"
            :class="{'complete': item.complete}"
            @click="onItemClick(item)"
          >
            {{ $t(`gamification.${item.key}`) }}
          </p>
        </component>

        <div>
          <img v-if="!item.complete" class="status-icon" src="@/assets/img/business-guide/status-false.svg" alt="status-false">
          <img v-if="item.complete" class="status-icon" src="@/assets/img/business-guide/status-true.svg" alt="status-true">
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'GamificationItemsList',

  computed: {
    ...mapGetters('user/gamification', ['getItems'])
  },

  methods: {
    ...mapMutations('ui', ['setDialogUserInviteState']),

    onItemClick (item) {
      if (item.complete) return

      switch (item.key) {
        case 'invite_collaborator':
          this.setDialogUserInviteState({ visible: true })
          break
        case 'add_note':
          this.$router.push({ name: 'idea-plan' })
          break
        case 'complete_step':
          this.$router.push({ name: 'business-guide' })
          break
        case 'add_financial_item':
          this.$router.push({ name: 'financial-plan' })
          break
      }
    }
  }
}
</script>

<style scoped lang="scss">
.gamification-items-list-wrapper {

  .item-wrapper {
    margin: 0 0 12px 0;

    .item {
      font-size: 16px;
      opacity: .5;
      text-decoration: underline;
      cursor: pointer;
      transition: color 0.3s ease, opacity 0.3s ease;

      &:hover {
        color: $color-black;
        opacity: 1;
      }

      &.complete {
        opacity: 1;
        text-decoration: none;
        cursor: default;

        &:hover {
          color: inherit;
        }
      }
    }
  }
}
</style>

<template>
  <ib-card
    class="home-card"
    :to="to"
    @click="$emit('click')"
  >
    <div class="title-and-img">
      <img :src="img" alt="img">
      <div class="d-flex flex-column">
        <span class="home-card-title">{{ title }}</span>
        <div>
          <slot />
        </div>
      </div>
    </div>
    <div v-if="showUpgrade" class="tag-upgrade font-outfit-regular">
      <i class="ib-icon ib-icon-rocket upgrade-icon" />
      {{ $t('upgrade') }}
    </div>
  </ib-card>
</template>

<script>
import MixinProgressBar from '@/mixins/progressBar'

export default {
  name: 'IdeaHomeCard',

  mixins: [MixinProgressBar],

  props: {
    title: {
      type: String,
      required: true
    },

    img: {
      type: String,
      required: true
    },

    showUpgrade: {
      type: Boolean,
      default: false
    },

    to: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.home-card {
  padding: 18px 17px 18px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-and-img {
    display: flex;
    align-items: center;
    column-gap: 20px;
    color: $color-navy;
  }

  .home-card-title {
    font-size: 18px;
  }

  .tag-upgrade {
    background: $color-yellow;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 13px;
    padding: 0 7px;
    height: 26px;

    .upgrade-icon {
      color: $color-white;
      font-size: 16px;
      margin-right: 3px;
    }
  }
}
</style>

<template>
  <ib-card
    class="learn-more-and-help-card"
    :to="to"
    @click="$emit('click')"
  >
    <img :src="img" alt="img">
    <div class="title-and-subtitle">
      <span class="learn-more-title">{{ title }}</span>
      <span class="learn-more-subtitle">{{ subtitle }}</span>
    </div>
  </ib-card>
</template>

<script>
export default {
  name: 'LearnMoreAndHelpCard',

  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.learn-more-and-help-card {
  padding: 18px 17px 18px 20px;
  display: flex;
  align-items: center;
  column-gap: 24px;

  .title-and-subtitle {
    display: flex;
    flex-direction: column;

    .learn-more-title {
      font-size: 18px;
    }

    .learn-more-subtitle {
      opacity: 0.5;
    }
  }
}
</style>
